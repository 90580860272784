<template>
  <div v-if="isVisible" class="py-16 px-4 sm:px-16" :class="[backgroundColor, outerContainerLayout, textAlignStyling]">
    <div v-track:view="gtmData()" :class="[imageContainerLayout]">
      <FixedAspect v-if="imageSource && imageSource.url" :class="{ 'order-last': imagePosition === 'right' }" radio="1">
        <CldnImg
          quality="70"
          :src="imageSource.url"
          :alt="imageSource.title"
          sizes="640px"
          class="max-w-none block w-full select-none"
        />
      </FixedAspect>
      <div>
        <div class="max-w-3xl" :class="{ 'mx-auto ': textAlign !== 'left' }">
          <span v-if="teaser" class="text-sm" :class="[teaserColor]">{{ teaser }}</span>
          <div v-if="headline" class="fluid-3xl leading-tightest font-semibold" v-html="headline"></div>
          <div
            v-if="description"
            class="text-copy checkmark-bullets checkmark-noborder mt-4"
            :class="{ 'checkmark-bullets-centered': textAlign !== 'left' }"
            v-html="description"
          ></div>
        </div>
        <form
          v-track:click="gtmData()"
          class="mt-4 max-w-2xl"
          :class="[textAlignStyling, { 'mx-auto ': textAlign !== 'left' }]"
          @submit.prevent="handleSubmit"
        >
          <LargeInput v-model="email" v-bind="{ theme, disabled: success }" />

          <input v-model="honeypot" class="block" type="hidden" name="name" placeholder="name" autocomplete="off" />

          <SmallNotice v-if="error" type="error" :message="$t(error)" class="mt-3" />
          <SmallNotice v-if="success" type="success" :message="$t(success)" class="mt-3" />

          <button
            type="submit"
            :disabled="isProcessing"
            class="mt-3 fluid-sm rounded-full px-10 py-3 font-normal tracking-normal focus:outline-none focus:ring-2 ring-offset-2"
            :class="[buttonColor, { 'pointer-events-none opacity-75': isProcessing }]"
          >
            {{ buttonText ? buttonText : $t('submit') }}
          </button>

          <div v-if="legalText" class="mt-8 fluid-xs leading-tightest text-copy" v-html="legalText"></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CldnImg from '@/components/CldnImg.vue';
import FixedAspect from '@/components/common/FixedAspect.vue';
import LargeInput from '@/components/forms/shared/LargeInput.vue';
import SmallNotice from '@/components/forms/shared/SmallNotice.vue';

export default {
  components: { CldnImg, FixedAspect, LargeInput, SmallNotice },

  props: {
    // Appearance
    theme: {
      type: String,
      default: 'dark',
    },
    layout: {
      type: String,
      default: 'full',
    },
    image: {
      type: [Array, Object],
      default: undefined,
    },
    imagePosition: {
      type: String,
      default: 'left',
    },
    textAlign: {
      type: String,
      default: 'center',
    },

    // Text
    headline: {
      type: String,
      default: undefined,
    },
    teaser: {
      type: String,
      default: undefined,
    },
    description: {
      type: String,
      default: undefined,
    },
    legalText: {
      type: String,
      default: undefined,
    },
    buttonText: {
      type: String,
      default: undefined,
    },

    // Mailchimp API
    mailchimpListId: {
      type: String,
      default: '6e01c1cfbe',
    },
    mailchimpTags: {
      type: String,
      default: undefined,
    },

    newsletterBackend: {
      type: String,
      default: 'mailchimp',
    },

    // Fallback
    globalFallback: {
      type: Boolean,
      default: false,
    },

    // Tracking
    blockPosition: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      email: '',
      honeypot: null,
      error: undefined,
      success: undefined,
      isProcessing: false,
    };
  },

  computed: {
    isVisible() {
      if (!this.globalFallback) return true;

      return this.$store.getters['newsletterBlocks/showGlobalFallback'];
    },

    imageSource() {
      if (Array.isArray(this.image)) {
        return this.image[0];
      }

      return this.image;
    },

    textAlignStyling() {
      if (this.textAlign === 'left') {
        return 'text-left';
      }

      return 'text-center';
    },

    outerContainerLayout() {
      switch (this.layout) {
        case 'contained':
          return 'lg:container';

        case 'full':
          return 'w-full';

        default:
          return '';
      }
    },

    imageContainerLayout() {
      return {
        'grid md:grid-cols-2 gap-4 md:gap-8 items-center': this.imageSource,
        'lg:container max-w-full': this.layout === 'full',
      };
    },

    backgroundColor() {
      return this.theme === 'dark' ? 'bg-blackly text-white' : 'bg-br-green-light text-blackly';
    },

    teaserColor() {
      return this.theme === 'dark' ? 'text-br-green' : 'text-blackly';
    },

    buttonColor() {
      return this.theme === 'dark'
        ? 'bg-br-green hover:bg-transparent text-white hover:text-br-green ring-br-green border-2 border-br-green ring-offset-blackly'
        : 'bg-transparent text-blackly hover:bg-blackly focus:bg-blackly focus:text-white hover:text-white border-2 border-blackly ring-br-green ring-offset-br-green-light';
    },
  },

  created() {
    if (!this.globalFallback) {
      this.$store.commit('newsletterBlocks/increment');
    }
  },

  methods: {
    handleSubmit() {
      if (this.success) {
        return;
      }

      this.error = undefined;
      this.isProcessing = true;

      // honeypot spam protection
      if (this.honeypot) {
        this.success = 'newsletterSignupSuccess';
        return;
      }

      if (this.newsletterBackend === 'klaviyo') {
        this.handleKlaviyo();
      } else {
        this.handleMailchimp();
      }
    },

    async handleKlaviyo() {
      // we need email and mailchimpListId
      if (!this.email || !this.mailchimpListId) {
        this.error = 'newsletter.newsletterSignupError';
        this.isProcessing = false;
        return;
      }

      try {
        const params = new URLSearchParams();

        params.append('email', this.email);
        params.append('list', this.mailchimpListId);
        params.append('tags', this.mailchimpTags);
        params.append('source', this.$route.path);

        const consent = this.$getCookieConsent();

        if (consent) {
          params.append('consentId', consent.consentId);

          params.append('consentPerformance', consent.consent.performance);
          params.append('consentFunctional', consent.consent.functional);
          params.append('consentTargeting', consent.consent.targeting);
          params.append('consentSocial', consent.consent.social);
        }

        await this.$axios.get('/api/klaviyo?' + params.toString());

        this.success = 'newsletterSignupSuccess';
      } catch (e) {
        this.error = 'newsletter.newsletterSignupError';
      } finally {
        this.isProcessing = false;
      }
    },

    async handleMailchimp() {
      const params = new URLSearchParams();
      params.append('mail', this.email);

      if (this.mailchimpListId) {
        params.append('list', this.mailchimpListId);
      }

      if (this.mailchimpTags) {
        params.append('tags', this.mailchimpTags);
      }

      const apiUrl = this.$config.mailchimp.vercelUrl;
      const request = apiUrl + '?' + params.toString();

      try {
        const response = await this.$axios.get(request);

        if (response.data === 'subscribed') {
          this.success = 'newsletterSignupSuccess';
        } else {
          this.success = 'newsletter.newsletterSignupUpdate';
        }
      } catch (error) {
        this.error = 'newsletter.newsletterSignupError';
      } finally {
        this.isProcessing = false;
      }
    },

    gtmData() {
      return {
        promotion_id: this.headline?.toLowerCase().replace(/ /g, '-'),
        promotion_name: [this.$route?.name, this.headline].join(' / '),
        creative_name: 'newsletterSignupForm',
        creative_slot: [this.$route?.name, this.blockPosition + 1].join(' / '),
      };
    },
  },
};
</script>

<!-- eslint-disable no-unreachable -->
<template>
  <div
    v-if="deal && bundleAvailable"
    class="py-8"
    :class="[applyClassesDependingOnBundleSize('md:bg-putty xl:py-20', 'md:bg-putty lg:py-20', 'md:bg-putty md:py-20')]"
  >
    <div
      class="flex flex-wrap items-center"
      :class="[
        applyClassesDependingOnBundleSize(
          'xl:flex-nowrap px-8 max-w-screen-2xl mx-auto',
          'lg:flex-nowrap px-8 max-w-screen-2xl mx-auto',
          'md:flex-nowrap container'
        ),
      ]"
    >
      <div
        class="flex flex-col justify-center w-full text-center relative shrink-0"
        :class="[
          applyClassesDependingOnBundleSize('xl:text-left xl:pr-4', 'lg:text-left lg:pr-4', 'md:text-left md:pr-4'),
          applyClassesDependingOnBundleSize(
            'xl:max-w-xs',
            'lg:max-w-xs lg:mr-10',
            'md:max-w-xs lg:max-w-sm 2xl:max-w-lg'
          ),
        ]"
      >
        <div class="w-full text-br-green fluid-sm leading-tight">
          {{ overline }}
        </div>
        <div class="fluid-3xl leading-tightest font-semibold my-4 w-full">{{ heading }}</div>
        <div>{{ bundleNames }}</div>
        <CrossSellAction
          :price="deal.bundlePrice"
          :compare-price="
            deal.bundleCompareAtPrice.amount !== deal.bundlePrice.amount ? deal.bundleCompareAtPrice : null
          "
          :is-available="bundleAvailable"
          :is-loading="isSubmitting"
          class="hidden w-full mt-4"
          :class="[applyClassesDependingOnBundleSize('xl:block', 'lg:block', 'md:block')]"
          @addToCart="addToCart"
        />
      </div>
      <div
        class="w-full mt-6 mx-auto"
        :class="[
          layoutClasses,
          applyClassesDependingOnBundleSize('xl:mt-0 xl:w-auto', 'lg:mt-0 lg:w-auto', 'md:mt-0 md:w-auto'),
        ]"
      >
        <ProductCardLight
          v-for="(item, index) in productsList"
          :key="index"
          v-bind="{
            title: item.title,
            description: item.productCardUsp,
            handle: item.handle,
            image: item.selected.image,
            price: item.selected.price,
            compareAtPrice: item.selected.compareAtPrice,
            unitPrice: item.selected.unitPrice,
            unitPriceMeasurement: item.selected.unitPriceMeasurement,
            discountPerProduct: deal.isBogo && index === 0 ? false : deal.discountPerProduct,
            variants: item.variants,
            selectedId: item.selected.id,
          }"
          v-track:view.item="gtmData(item, index)"
          v-track:click.item="gtmData(item, index)"
          class="last:after:hidden after:content-['+'] after:absolute after:top-1/2 after:left-full after:font-bold after:text-center after:-mt-4"
          :class="[
            applyClassesDependingOnBundleSize(
              'after:w-10 lg:after:w-8 after:fluid-xl leading-tight font-medium',
              'after:w-10 lg:after:w-16 xl:after:w-24 after:fluid-3xl leading-tightest',
              'after:w-10 lg:after:w-24 after:fluid-3xl leading-tightest'
            ),
          ]"
          @update:selected="updateSelectedItem($event, index)"
        />
      </div>

      <!-- This is the mobile Add To Cart button -->
      <CrossSellAction
        :price="deal.bundlePrice"
        :compare-price="deal.bundleCompareAtPrice.amount !== deal.bundlePrice.amount ? deal.bundleCompareAtPrice : null"
        :is-available="bundleAvailable"
        :is-loading="isSubmitting"
        class="text-center block w-full mt-4"
        :class="[applyClassesDependingOnBundleSize('xl:hidden', 'lg:hidden', 'md:hidden')]"
        @addToCart="addToCart"
      />
    </div>

    <PreAssessmentModal
      v-if="hasPreAssessment && showPreAssessmentModal"
      :showing="showPreAssessmentModal"
      :info="agreementCategory[0]"
      @buy="addDealToCart"
      @close="showPreAssessmentModal = false"
    />
  </div>
</template>

<script>
import CrossSellAction from './CrossSellAction.vue';
import ProductCardLight from '~/components/shop/products/ProductCardLight';
import PreAssessmentModal from '~/components/common/blocks/PreAssessmentModal';
import { Bus } from '@/utils/bus';

export default {
  name: 'CrossSell',

  components: {
    ProductCardLight,
    CrossSellAction,
    PreAssessmentModal,
  },

  props: {
    heading: {
      type: String,
      default: null,
    },
    overline: {
      type: String,
      default: null,
    },
    product: {
      type: Object,
      default: null,
    },
    useProductOverwrite: {
      type: Boolean,
      default: false,
    },
    triggerProductOverwrite: {
      type: String,
      default: null,
    },
    pickystoryDealType: {
      type: String,
      default: null,
    },
    agreementCategory: {
      type: Array,
      default: () => null,
    },
  },

  data() {
    return {
      deal: null,
      isSubmitting: false,
      productsList: null,
      showPreAssessmentModal: false,
    };
  },

  computed: {
    hasPreAssessment() {
      return this.agreementCategory?.length > 0;
    },

    bundleSize() {
      return this.productsList?.length ?? 0;
    },

    layoutClasses() {
      return {
        'grid grid-cols-2 gap-x-10 lg:gap-x-24': this.bundleSize === 2,
        'grid grid-cols-3 gap-x-10 lg:gap-x-16 xl:gap-x-24': this.bundleSize === 3,
        'grid grid-cols-4 gap-x-10 lg:gap-x-8': this.bundleSize === 4,
      };
    },

    bundleAvailable() {
      if (!this.productsList?.length) {
        return false;
      }

      try {
        return !this.productsList.some((product) => {
          return !product.variants.some((variant) => {
            return variant.available;
          });
        });
      } catch (e) {
        return false;
      }
    },

    bundleNames() {
      return this.productsList?.map((product) => product.title).join(' + ');
    },
  },

  mounted() {
    // Quit early if we don't want to show the xsell teasers
    if (this.$store.state.app.showXSellTeasers === false) {
      return;
    }

    if (this.$pickystory.isPickyStoryReady()) {
      this.initDeal();
    } else {
      Bus.$on('pickystory-ready', this.initDeal);
    }
  },

  methods: {
    async initDeal() {
      const triggerHandle =
        this.useProductOverwrite && this.triggerProductOverwrite && this.triggerProductOverwrite.length
          ? this.triggerProductOverwrite
          : this.product.handle;

      const dealType =
        this.pickystoryDealType === 'bnd' ? this.$pickystory.types.BND_TYPE : this.$pickystory.types.FBT_TYPE;
      const locationName = this.pickystoryDealType === 'bnd' ? null : 'xsell-pdp';

      try {
        this.deal = await this.$pickystory.findDeal(locationName, triggerHandle, dealType);

        if (this.deal) {
          this.productsList = this.deal.isBogo
            ? [...this.deal.products, ...this.deal.discountProducts]
            : this.deal.products;

          this.productsList = this.productsList.map((product) => {
            // foreach product, find the first available variant and set it as selected
            return { ...product, selected: product.variants.find((v) => v.available) };
          });
        }
      } catch (e) {
        console.warn(e);
      }
    },

    updateSelectedItem(selected, index) {
      const newSelected = this.productsList[index].variants.find((variant) => {
        return variant.id === parseInt(selected);
      });

      this.productsList[index].selected = newSelected;
    },

    applyClassesDependingOnBundleSize(largeClasses, mediumClasses, smallClasses) {
      return {
        [largeClasses]: this.bundleSize === 4,
        [mediumClasses]: this.bundleSize === 3,
        [smallClasses]: this.bundleSize === 2,
      };
    },

    addToCart() {
      if (this.hasPreAssessment) {
        this.showPreAssessmentModal = true;
      } else {
        this.addDealToCart();
      }
    },

    addDealToCart() {
      if (this.pickystoryDealType === 'bnd') {
        this.addBNDtoCart();
      } else {
        this.addFBTtoCart();
      }
    },

    async addFBTtoCart() {
      try {
        this.isSubmitting = true;
        let pos = 0;

        const products = this.deal.products.map((product) => {
          pos += 1;

          // find selected variant for this products
          const variant = this.productsList.find((item) => item.id === product.id).selected;

          return {
            id: variant.id,
            position: pos,
          };
        });

        const addonProducts = this.deal.discountProducts.map((product) => {
          pos += 1;

          // find selected variant for this products
          const variant = this.productsList.find((item) => item.id === product.id).selected;

          return {
            id: variant.id,
            position: pos,
          };
        });

        await this.deal.pickystory.addVariantsToCart(products, 1, this.deal.isBogo ? addonProducts : null);
        await this.$store.dispatch('cart/refreshCheckout');

        this.$store.dispatch('cart/open');
      } catch (error) {
        console.warn(error);
      } finally {
        this.isSubmitting = false;
      }
    },

    async addBNDtoCart() {
      try {
        this.isSubmitting = true;
        let pos = 0;

        const products = this.deal.products.map((product) => {
          pos += 1;

          // find selected variant for this products
          const variant = this.productsList.find((item) => item.id === product.id).selected;

          return {
            id: variant.id,
            position: pos,
          };
        });

        // remove all products that are already in the cart first
        await this.$store.dispatch(
          'cart/removeLineItems',
          products.map((product) => product.id)
        );

        await this.deal.pickystory.addVariantsToCart(products, 1);
        await this.$store.dispatch('cart/refreshCheckout');

        this.$store.dispatch('cart/open');
      } catch (error) {
        console.warn(error);
      } finally {
        this.isSubmitting = false;
      }
    },

    gtmData(payload, index) {
      try {
        const variant = payload.selected;
        const itemSKU = variant.sku;

        return {
          item_list_id: [this.$route?.name, this.heading.toLowerCase().replace(/ /g, '-')].join(' / '),
          item_list_name: [this.$route?.name, this.heading].join(' / '),
          index,
          item_id: itemSKU,
          price: variant.price.amount,
          currency: variant.price.currencyCode,
          item_name: payload.title,
        };
      } catch (e) {
        return {};
      }
    },
  },
};
</script>
